<template>

   <div ref="dropdownWrapper">
      <div class="flex flex-row items-center gap-2 w-full bg-white" :class="class">
         <div @click="toggleCountryList" class="flex flex-row items-center gap-2 cursor-pointer">
            <span>
               <img v-if="selectedCountry" class="min-w-[20px] max-w-[20px]" :src="`/assets/flags/${selectedCountry.flag.toLowerCase()}.svg`" :alt="`sweden flag`">
            </span>
            <span v-if="selectedCountry" :class="sleek ? 'text-sm' : ''">+{{ selectedCountry.code }}</span>
            <span>
               <font-awesome-icon size="sm" icon="fa-solid fa-chevron-down" class="text-theme-active" />
            </span>
         </div>
         <input @input="handlePhoneNumberInput" v-model="phoneNumber" placeholder="Enter your Phone Number" type="text" :class="sleek ? 'text-sm' : ''" class="outline-none w-full h-30 font-bold text-size-6 border-none w-full bg-transparent">
      </div>
      <ul v-if="countries.open" @click.stop class="z-10 max-h-[200px] overflow-y-auto top-full absolute right-0 p-4 flex flex-col gap-2 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5">
         <li>
            <input class="w-full border bordet-theme-gray-7 text-size-4 text-gray-5 outline-none px-2 py-1" placeholder="Search country" type="text" @input="handleCountrySearch">
         </li>
         <li @click="handleCountrySelect(country)" v-for="country in fillteredCountryList" :index="country.id" class="flex flex-row items-center gap-3 cursor-pointer hover:bg-gray-100 p-1">
            <img class="min-w-[20px] max-w-[20px]" :src="`/assets/flags/${country.flag.toLowerCase()}.svg`" alt="sweden flag">
            <span class="text-theme-gray-2">{{ country.name }}</span>
            <span class="text-theme-gray-2 font-light">+{{ country.code }}</span>
         </li>
      </ul>
   </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue'
import Country from '@/composables/Country'

interface CountryType {
   id: number;
   name: string;
   flag: string;
   code: string;
}

const props = defineProps(['class', 'sleek'])
const emit = defineEmits(['phoneNumber', 'number'])
const { countriesList, getUserCountry } = Country()
const dropdownWrapper = ref()
const phoneNumber = ref<string>('')
const countries = ref({
   open: false,
   search: '',
   list: []
})

const fillteredCountryList = computed(() => {
   return countries.value.list.filter((country: CountryType) => country.name.toLowerCase().includes(countries.value.search)) as CountryType[];
})

const selectedCountry = ref<CountryType| null>(null)

const toggleCountryList = () => {
   countries.value.open = !countries.value.open
   countries.value.search = ''
}

const handleCountrySelect = (country: any) => {
   selectedCountry.value = country
   countries.value.open = false
}

const handleCountrySearch = (event:any) => {
   const input = event.target.value.toLowerCase()
   countries.value.search = input
}

const handlePhoneNumberInput = (event: any) => {
   phoneNumber.value = event.target.value.replace(/\D/g, '')

   if (phoneNumber.value.length > 10) {
      phoneNumber.value = phoneNumber.value.toString().slice(0, 10)
   }

   if (phoneNumber.value.length) {
      emit('phoneNumber', selectedCountry.value?.code + phoneNumber.value)
   } else {
      emit('phoneNumber', false)
   }
}

const handleClickOutside = (event: any) => {
   
   if (dropdownWrapper.value && !dropdownWrapper.value.contains(event.target as Node)) {
      countries.value.open = false
      countries.value.search = ''
   }
}

onMounted(async () => {
   const response = await countriesList()

   if (response.status == 200) {
      countries.value.list = response.data.data
   }

   const currentCountry = await getUserCountry()
   if (currentCountry.status == 200) {
      selectedCountry.value = currentCountry.data.country
   }

   document.addEventListener('click', handleClickOutside);
})

onUnmounted(() => {
   document.removeEventListener('click', handleClickOutside);
})

</script>