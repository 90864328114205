import { getUserToken, getBackendBaseUrl } from "@/utils/common"
import { LeadForm } from '@/types/lead'
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const SavedCard = () => {

   const getHeaders = () => { 
      return {
         'Authorization': `Bearer ${getUserToken()}`,
         'Content-Type': 'application/json',
      }
   }

   const savedCardList = async () : Promise<any> => {
      try {
         let response

         if (getUserToken() !== null) {
            response = await axios.get(baseUrl + 'api/cards/list', {headers: getHeaders()})
         }

         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      savedCardList
   }
}

export default SavedCard