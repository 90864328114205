<template>
   <div class="flex flex-col md:flex-row items-stretch gap-12 md:gap-8">
      <div class="flex-1 flex flex-col w-full">
         <Search layout="full" :type="data.driveType" :data=data :proceed="proceed" @proceedInitiated="proceed = false" @pickUp="handlePickUpChange" @dropOff="handleDropOffChange" />
         <div @click="handleEditSearch" class="md:ml-auto hover:bg-white hover:text-theme-active text-center border-2 border-theme-active bg-theme-active text-white gap-3 md:w-fit md:ml-auto mt-12 py-3 px-7 transition ease-in-out duration-300 cursor-pointer rounded-full">Edit Search</div>
      </div>
      <div class="flex flex-col flex-1">
         <div class="flex">
            <GoogleMap :origin="origin" :destination="destination" :driveType="data.driveType" @match="handleMatchedLocation" @distance="handleMapData" @time="handleMapData" />
         </div>
         <div class="flex-grow border-b border-l border-r border-theme-gray-7 grid grid-cols-2 divide-x divide-theme-gray-7 gap-4 md:gap-0 py-4">
            <div v-if="data.driveType == 'distance'" class="col-span-2 md:col-span-1 flex flex-col md:flex-row items-center justify-center gap-2 md:gap-6">
               <div>
                  <span class="font-icon theme-icon dual-maps-icon text-theme-active text-[48px]"></span>
               </div>
               <div class="flex flex-col items-start">
                  <div class="uppercase theme-gray-2 font-light text-small">Total Distance</div>
                  <div class="flex flex-row theme-gray-5 text-head-md-3">{{ distance }}</div>
               </div>
            </div>
            <div v-if="data.driveType == 'distance'" class="col-span-2 md:col-span-1 flex flex-col md:flex-row items-center justify-center gap-2 md:gap-6">
               <div>
                  <span class="font-icon theme-icon clock-icon text-theme-active text-[48px]"></span>
               </div>
               <div class="flex flex-col items-start">
                  <div class="uppercase theme-gray-2 font-light text-small">Total Time</div>
                  <div class="flex flex-row theme-gray-5 text-head-md-3">{{ time }}</div>
               </div>
            </div>
            <div v-if="data.driveType == 'hourly'" class="col-span-2 flex flex-col md:flex-row items-center ml-8 gap-2 md:gap-6">
               <div>
                  <span class="font-icon theme-icon map-search-icon text-theme-active text-[48px]"></span>
               </div>
               <div class="flex flex-col items-start">
                  <div class="uppercase theme-gray-2 font-light text-small">Search Scope</div>
                  <div class="flex flex-row theme-gray-5 text-head-md-3">{{ matchedLocation ? matchedLocation.name: '' }}</div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div @click="handleBookingProceed" class="flex flex-row items-center rounded-full justify-center cursor-pointer hover:bg-white hover:text-theme-active border-2 border-theme-active bg-theme-active text-white gap-3 md:w-fit md:ml-auto mt-12 py-3 px-7 transition ease-in-out duration-300">
      <div class="uppercase">Choose a vehicle</div>
      <div class="md:ml-auto mr-2">
         <font-awesome-icon size="sm" icon="fa-solid fa-chevron-right" />
      </div>
   </div>
   <Loader :show="showLoader" />
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { Locality, LocationSchedule } from '@/types/index'
import { disableScroll, enableScroll } from '@/utils/common'
import Search from '@/components/Search.vue'
import GoogleMap from '@/components/GoogleMap.vue'
import { decodeFromQuery, saveUserTempSearch } from '@/services/search-service'
import Loader from '@/widgets/Loader.vue'
import { useRouter } from 'vue-router'

const props = defineProps(['data'])
const origin = ref(null)
const destination = ref(null)
const distance = ref<string>('0 km')
const time = ref<string>('0h 0m')
const proceed = ref<boolean>(false)
const matchedLocation = ref<any>(null)
const router = useRouter()
const showLoader = ref<boolean>(false)

const handlePickUpChange = (value: LocationSchedule) => {
   origin.value = value.location.id as any
}

const handleDropOffChange = (value: LocationSchedule) => {
   destination.value = value.location.id as any
}

const handleMapData = (value: string, type: string) => {
   if (type == 'distance') {

      distance.value = value
   } else if(type == 'time') {

      time.value = value
   }
}

const handleMatchedLocation = (match: any) => {

   matchedLocation.value = match != 'not-found' ? match : { name: props.data.pickUp.location.name }
}

const handleBookingProceed = () => {
   proceed.value = true
}

const handleEditSearch = () => {
   const queryString = window.location.search.substring(1)
   const search = decodeFromQuery(queryString)
   saveUserTempSearch(search)
   router.push({name: 'Home'})
}

onMounted(() => {

   showLoader.value = true
   disableScroll()

   setTimeout(async () => {
      
      showLoader.value = false
      enableScroll()
   }, 2000)
})

</script>