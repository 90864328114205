<template>
   <div class="w-full mb-5">
      <label for="name" :class="hasError ? 'text-gray-700' : 'text-theme-error'" class="block text-sm font-medium">Search</label>
      <div class="flex flex-row items-center w-full">
         <input v-model="location" type="text" :class="!hasError ? 'border-gray-300' : 'border-theme-error'" class="mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-gray-500 text-sm">
         <div v-if="isLoading" class="ml-auto">
            <LoaderIcon />
         </div>
         <div v-if="hasError" class="ml-auto">
            <font-awesome-icon size="1x" icon="fa-solid fa-circle-exclamation" class="text-theme-error" />
         </div>
      </div>
      <ul v-if="isDropOpen && locations.length" class="z-10 !mt-0 max-h-[200px] overflow-y-auto origin-top-right absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5">
         <li @click="handleSelect(item)" v-for="(item, index) in locations" :key="index" class="flex flex-row items-start gap-2 py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer">
            <span>{{ item.name }}</span>
         </li>
      </ul>
   </div>
   <div class="flex">
      <button @click="handleSubmit()" type="button" class="ml-auto mt-20 bg-theme-active py-2 px-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white outline-none">
         Save
      </button>
   </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { CarType, Locality, Supplier } from '@/types/index'
import Admin from '@/composables/admin/index'
import { resetErrors, handleApiError } from '@/utils/common'
import LoaderIcon from '@/components/LoaderIcon.vue'

const props = defineProps(['mode'])
const emit = defineEmits(['success', 'error'])
const hasError = ref<boolean>(false)
const isLoading = ref<boolean>(false)
const isDropOpen = ref<boolean>(false)
const location = ref<string>('')
const locations = ref<Locality[]>([])
const selectedLocation = ref<Locality | null>(null)
const SEARCH_DELAY = 400
let searchTimeout: ReturnType<typeof setTimeout> | null = null

const handleSelect = (value: Locality) => {
   location.value = value.name
   selectedLocation.value = value
   isDropOpen.value = false
}

const validateForm = () : boolean => {

   let response = true

   if (selectedLocation === null) {
      response = false
   }

   return response 
}

const handleSubmit = async () => {

   hasError.value = false
   const isValid = validateForm()

   if (isValid) {

   }
}

</script>