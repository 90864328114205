
import { getBackendBaseUrl, getUserToken, getAdminToken } from "@/utils/common"
import axios from "axios"
import { useRouter } from "vue-router"

const baseUrl : string = getBackendBaseUrl()

const getHeaders = () => {

   const token = window.location.href.includes('/admin/') ? getAdminToken() : getUserToken()

   return {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
   }
}

const Dashboard = () => {

   const fetchData = async (mode: string, data: any, agent: string) : Promise<any> => {
      try {
         const response = await axios.get(`${baseUrl}api/dashboard/${mode}/${data}/${agent}`, {params: {},headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      fetchData
   }
}

export default Dashboard