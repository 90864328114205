<template>

   <TopBanner layout="normal" currentPage="Booking" heading="Booking" bannerClass="banner-booking" />

   <div class="flex flex-col py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0">
      <div v-if="props.step !== 'finish'" class="hidden md:grid grid-cols-3 booking-steps">
         <div v-for="(step, index) in steps" :key="index" class="relative col-span-1">
            <div v-if="index + 1 != steps.length" class="border-b border-theme-gray-7 absolute top-[30%] left-1/2 w-full"></div>
            <div class="flex flex-col items-center gap-3">
               <div class="z-10 min-w-[50px] min-h-[50px] font-bold text-size-6 p-3 text-center rounded-full" :class="step.isActive ? 'bg-theme-active text-white' : 'text-theme-gray-2 bg-theme-gray-7'">
                  <span v-if="step.isActive || !step.isCompleted">{{ index+1 }}</span>
                  <span v-else>
                     <font-awesome-icon size="lg" icon="fa-solid fa-check" class="text-theme-active" />
                  </span>
               </div>
               <div class="text-theme-gray-5 text-size-3">{{ step.title }}</div>
            </div>
         </div>
      </div>
      <div v-if="props.step !== 'finish'" class="md:hidden relative">
         <div class="flex flex-col" @click="toggleStepsDrop">
            <div class="border border-theme-gray-7 px-3 py-5 font-bold">{{ currentStep?.title }}</div>
            <ul v-if="isStepsDropOpen" class="z-10 max-h-[200px] overflow-y-auto origin-top-right absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5">
               <li v-for="(step, index) in steps" :key="index" class="py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer">
                  {{ index + 1 }}. {{ step.title }}
               </li>
            </ul>
            <font-awesome-icon size="lg" icon="fa-solid fa-chevron-down" class="text-theme-active absolute right-2 bottom-[25px]" />
         </div>
      </div>

      <div v-if="step == 'ride-details'" class="flex flex-col mt-12">
         <RideDetails :data="searchData" />
      </div>
      <div v-else-if="step == 'choose-vehicle'" class="flex flex-col mt-12">
         <ChooseVehicle :currentStep="currentStep" />
      </div>
      <div v-else-if="step == 'contact-details' " class="flex flex-col mt-12">
         <ContactDetails :currentStep="currentStep" />
      </div>
      <div v-else-if="step == 'finish'" class="flex flex-col mt-12">
         <Finish :currentStep="currentStep" />
      </div>
   </div>

</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import TopBanner from '@/components/TopBanner.vue'
import RideDetails from '@/components/booking/RideDetails.vue'
import ChooseVehicle from '@/components/booking/ChooseVehicle.vue'
import ContactDetails from '@/components/booking/ContactDetails-back.vue'
import Finish from '@/components/booking/Finish.vue'
import { BookingStep, DriveType } from '@/types'
import { BOOKING_STEPS } from '@/utils/constants'
import { decodeFromQuery } from '@/services/search-service'

const props = defineProps(['step'])
const isStepsDropOpen = ref<boolean>(false)
const steps = ref<BookingStep[]>(BOOKING_STEPS)
const currentStep = ref<BookingStep | null>(null)
const searchData = ref<any>(null)

const toggleStepsDrop = () => {
   isStepsDropOpen.value = !isStepsDropOpen.value
}

const handleStepState = () => {
   steps.value.forEach((step : BookingStep) => {
      step.isActive = false
      if (step.uri === props.step) {
         step.isActive = true
         currentStep.value = step
         const rSteps = steps.value.filter((item: BookingStep) => item.id !== step.id)
         rSteps.forEach((rStep : BookingStep) => {
            if (rStep.id < step.id) {
               rStep.isCompleted = true
            }
         })
      }
   })

   if (props.step == 'ride-details') {
      
      const queryString = window.location.search.substring(1)
      searchData.value = decodeFromQuery(queryString)
   }
}

watch(() => props.step, (newVallue, oldValue) => {
   handleStepState()
})

handleStepState()

</script>