<template>
   <Listbox as="div" v-model="selected">
      <ListboxLabel class="sr-only">Change option</ListboxLabel>
      <div class="relative">
         <div class="inline-flex divide-x divide-theme-active rounded-md shadow-sm">
            <div class="inline-flex items-center gap-x-1.5 rounded-l-md bg-theme-active px-3 py-2 text-white shadow-sm">
               <font-awesome-icon size="1x" icon="fa-solid fa-check -ml-0.5" />
               <p class="text-sm font-semibold">{{ selected.title }}</p>
            </div>
            <ListboxButton class="inline-flex items-center rounded-l-none rounded-r-md bg-theme-active p-2 outline-none">
               <span class="sr-only">Change option</span>
               <font-awesome-icon size="1x" icon="fa-solid fa-chevron-down" class="text-white" />
            </ListboxButton>
         </div>

         <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <ListboxOptions class="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
               <ListboxOption @click="handleOptionSelect(option)" as="template" v-for="option in options" :key="option.title" :value="option" v-slot="{ active, selected }">
                  <li :class="[active ? 'bg-theme-active text-white' : 'text-gray-900', 'cursor-pointer select-none p-4 text-sm']">
                     <div class="flex flex-col">
                        <div class="flex justify-between">
                           <p :class="selected ? 'font-semibold' : 'font-normal'">{{ option.title }}</p>
                           <span v-if="selected" :class="active ? 'text-white' : 'text-theme-active'">
                              <font-awesome-icon size="lg" icon="fa-solid fa-check" :class="active ? 'text-white' : 'text-theme-active'" />
                           </span>
                        </div>
                        <p :class="[active ? 'text-theme-active' : 'text-gray-500', 'mt-2']">{{ option.description }}</p>
                     </div>
                  </li>
               </ListboxOption>
            </ListboxOptions>
         </transition>
      </div>
   </Listbox>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'

const props = defineProps(['data', 'selected', 'name'])
const emit = defineEmits(['selected'])

const options = props.data
const selected = ref(props.selected ? props.selected : options[0])

const handleOptionSelect = (option: any) => {
   emit('selected', props.name, option.value)
}

</script>