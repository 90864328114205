<template>
   <div class="w-full mb-5">
      <label for="name" :class="!errors.loyaltyThreshold ? 'text-gray-700' : 'text-theme-error'" class="block text-sm font-medium">Loyalty Threshold</label>
      <input v-model="form.loyaltyThreshold" type="text" :class="!errors.loyaltyThreshold ? 'border-gray-300 text-gray-500' : 'border-theme-error text-theme-error'" class="mt-1 outline-none border rounded-sm h-10 w-full block pl-2 text-sm">
   </div>
   
   <div class="flex">
      <button @click="handleSubmit()" type="button" class="ml-auto mt-20 bg-indigo-600 py-2 px-10 border border-transparent rounded-md shadow-sm text-sm font-medium text-white hover:bg-indigo-700 outline-none">
         Save
      </button>
   </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import Admin from '@/composables/admin/index'
import { resetErrors, handleApiError } from '@/utils/common'

const props = defineProps(['agent'])
const emit = defineEmits(['success', 'error'])
const { updateAgent } = Admin()
const isLoading = ref<boolean>(false)
const form = ref({
   loyaltyThreshold: 0
})
const errors = ref({
   loyaltyThreshold: false
})

onMounted(() => {
   mounted() 
})

const mounted = async () => {

   if (props.agent) {
      form.value = {
         loyaltyThreshold: props.agent.loyalty.threshold
      }
   }
}

const validateForm = () : boolean => {

   let response = true
   
   if (form.value.loyaltyThreshold == 0) {
      errors.value.loyaltyThreshold = true
      response = false
   }

   return response 
}

const handleSubmit = async () => {

   resetErrors(errors.value)
   const isValid = validateForm()

   if (isValid) {
      
      isLoading.value = true
      const response = await updateAgent(props.agent.id, form.value)
      isLoading.value = false
      if (response.status == 200) {
         emit('success')
      } else {
         const error = handleApiError(response)
         emit('error', error)
      }
   }
}

</script>