<template>
   <div class="flex flex-col min-h-[800px] relative">
      <div v-if="page && page.mediaType == 'image'" class="absolute top-0 left-0 bg-no-repeat bg-cover bg-center w-full h-full" :style="{ backgroundImage: `url(${page?.mediaSource})` }">
         <img class="w-full h-full" src="/assets/img/hero_overlay_3.png" alt="">
      </div>
      <div v-else-if="page && page.mediaType == 'video'" class="absolute top-0 left-0 bg-no-repeat bg-cover bg-center w-full h-full">
         <video muted autoplay loop class="w-full h-full" :src="page?.mediaSource"></video>
      </div>
      <div class="flex items-start flex-1 w-full mx-auto md:w-theme-landing-d mx-auto relative">
         <div class="flex flex-col gap-6 my-auto z-10">
            <div v-if="page?.bannerSubHeading" class="text-white text-size-8 animate__animated slide-down">{{ page?.bannerSubHeading }}</div>
            <div v-if="page?.bannerHeading" class="text-head-bigg leading-[70px] font-extra-bold text-white animate__animated slide-down" v-html="page?.bannerHeading.replace(/\n/g, '<br>')"></div>
            <div v-if="page?.bannerInfoText" class="flex flex-col text-white opacity-90 text-size-7 gap-2 animate__animated slide-up" v-html="page?.bannerInfoText.replace(/\n/g, '<br>')"></div>
            <div v-if="page?.bannerButtonText && page?.bannerButtonText != ''" @click="handleButtonClick" class="uppercase slide-up animate__animated bg-theme-active text-white w-[350px] text-size-8 tracking-wider cursor-pointer font-bold text-center py-4">{{ page?.bannerButtonText }}</div>
         </div>
         <div v-if="page?.showSupplierForm" class="px-5 opacity-90 max-w-[400px] py-8 bg-white absolute right-0 top-1/2 transform -translate-y-[50%] rounded-md">
            <div class="w-full">
               <div class="flex flex-col w-full mx-auto gap-6">
                  <div class="flex flex-col gap-2">
                     <label for="signup-fullname" :class="form.fullName.error ? 'text-ef-text' : 'text-theme-gray-4'" class="uppercase text-small font-bold text-black">Full name *</label>
                     <div :class="form.fullName.error ? 'border-ef-outline' : 'border-theme-gray-7'" class="flex flex-row items-center border">
                        <input v-model="form.fullName.value" type="text" id="signup-fullname" class="w-full outline-none text-theme-gray-5 p-1 pl-2 h-[30px] md:h-[40px] font-bold text-xs md:text-sm">
                     </div>
                     <span v-if="form.fullName.error" class="text-ef-error">{{ form.fullName.errorMessage }}</span>
                  </div>
                  <div class="flex flex-row items-center gap-3">
                     <div class="flex-1 flex flex-col gap-2">
                        <label for="signup-companyname" :class="form.companyName.error ? 'text-ef-text' : 'text-theme-gray-4'" class="uppercase text-small font-bold text-black">Company name *</label>
                        <div :class="form.companyName.error ? 'border-ef-outline' : 'border-theme-gray-7'" class="flex flex-row items-center border">
                           <input v-model="form.companyName.value" type="text" id="signup-companyname" class="w-full outline-none text-theme-gray-5 p-1 pl-2 h-[30px] md:h-[40px] font-bold text-xs md:text-sm">
                        </div>
                        <span v-if="form.companyName.error" class="text-ef-error">{{ form.companyName.errorMessage }}</span>
                     </div>
                     <div class="flex-1 flex flex-col gap-2">
                        <label for="signup-email" :class="form.email.error ? 'text-ef-text' : 'text-theme-gray-4'" class="uppercase text-small font-bold text-black">Email *</label>
                        <div :class="form.email.error ? 'border-ef-outline' : 'border-theme-gray-7'" class="flex flex-row items-center border">
                           <input v-model="form.email.value" type="email" id="signup-email" class="w-full outline-none text-theme-gray-5 p-1 pl-2 h-[30px] md:h-[40px] font-bold text-xs md:text-sm">
                        </div>
                        <span v-if="form.email.error" class="text-ef-error">{{ form.email.errorMessage }}</span>
                     </div>
                  </div>
                  <div class="flex flex-col gap-2">
                     <label for="signup-phonenumber" :class="form.phone.error ? 'text-ef-text' : 'text-theme-gray-4'" class="uppercase text-small font-bold text-black">Phone Number *</label>
                     <div class="flex flex-row items-center border border-theme-gray-7">
                        <div class="relative w-full">
                           <CountrySearchablePhoneField :sleek="true" phoneNumber="handlePhoneInput" class="h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6 px-2" />
                        </div>
                     </div>
                     <span v-if="form.phone.error" class="text-ef-error">{{ form.phone.errorMessage }}</span>
                  </div>
                  <div class="flex flex-col gap-2">
                     <label for="signup-message" class="uppercase text-small text-theme-gray-4 font-bold text-black">Message *</label>
                     <div class="flex flex-row items-center border border-theme-gray-7">
                        <input v-model="form.message.value" type="text" id="signup-message" class="w-full outline-none text-theme-gray-5 p-1 pl-2 h-[30px] md:h-[40px] font-bold text-xs md:text-sm">
                     </div>
                  </div>
                  <div class="flex flex-col md:items-end gap-4">
                     <div class="flex flex-col justify-end gap-2">
                        <div class="mt-6 ml-auto google-recaptcha"></div>
                        <p v-if="recaptchaError" class="text-ef-error ml-auto">{{ recaptchaError }}</p>
                     </div>
                     <div :class="isLoading ? 'cursor-not-allowed opacity-40' : 'cursor-pointer hover:text-theme-active hover:bg-white'" class="order-1 md:order-2 gap-4 uppercase w-full md:w-auto px-4 py-2 border-2 border-theme-active text-white bg-theme-active text-theme-gray-2 text-size-2 flex items-center justify-center ml-auto transition-all ease-in-out duration-500">
                        Submit
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div v-if="page?.sectionTitle || page?.sectionInfoText" class="w-full bg-theme-gray-7 py-20">
      <div class="flex flex-col md:w-theme-landing-d mx-auto gap-8">
         <div class="flex flex-col">
            <div class="font-extra-bold leading-[50px] text-head-xl mt-4 mb-8 tracking-wider">{{ page?.sectionTitle }}</div>
            <div class="text-gray-500" v-html="page?.sectionInfoText.replace(/\n/g, '<br>')"></div>
         </div>
      </div>
   </div>
   <div class="bg-white flex w-full py-20 md:w-theme-landing-d mx-auto gap-20">
      <div class="flex flex-1 relative">
         <div class="absolute -top-3 left-12">
            <div class="box-animate-one h-1/2">
               <img src="/assets/img/about_3_2.jpg" alt="">
            </div>
            <div class="box-animate-two bg-white p-2 h-1/2">
               <img src="/assets/img/about_3_3.jpg" alt="">
            </div>
         </div>
         <div class="ml-auto">
            <img src="/assets/img/about_3_1.jpg" alt="">
         </div>
      </div>
      <div class="flex flex-1 flex-col gap-8">
         <div class="flex flex-col">
            <div class="flex items-center">
               <img src="/assets/img/menu-1-svgrepo-com.svg" alt="">
               <div class="uppercase text-theme-active font-medium text-size-6">More About Us</div>
            </div>
            <div class="font-extra-bold leading-[50px] text-head-xl mt-4 mb-8 tracking-wider">
               For Over 3 Decades, We Have Been Providing The<br>Best Transport Services.
            </div>
            <p class="text-gray-500">
               We address our offer to all who want to travel in comfortable conditions. We have top-class vehicles, so our customers can be assured of comfort and safety.
            </p>
            <div class="flex gap-8 py-10 border-b border-gray-100 mb-10">
               <div class="flex flex-col items-center justify-center text-center gap-2 max-w-[120px] py-5 border border-gray-100 shadow-custom-symetric">
                  <div class="text-theme-active font-extra-bold text-head-lg">30+</div>
                  <div class="text-gray-500 leading-[25px]">Years Experience</div>
               </div>
               <div class="flex flex-col my-auto gap-3">
                  <div class="flex items-center gap-3">
                     <div class="flex items-center">
                        <font-awesome-icon icon="fa-solid fa-circle" class="text-[7px] text-theme-active" />
                     </div>
                     <div>Professionally Trained Chauffeurs</div>
                  </div>
                  <div class="flex items-center gap-3">
                     <div class="flex items-center">
                        <font-awesome-icon icon="fa-solid fa-circle" class="text-[7px] text-theme-active" />
                     </div>
                     <div>24/7 Full-Service Transportation</div>
                  </div>
                  <div class="flex items-center gap-3">
                     <div class="flex items-center">
                        <font-awesome-icon icon="fa-solid fa-circle" class="text-[7px] text-theme-active" />
                     </div>
                     <div>Global network of Transportation Partners</div>
                  </div>
               </div>
            </div>
            <div class="uppercase bg-theme-active text-white w-fit px-8 text-size-8 tracking-wider cursor-pointer font-bold text-center py-4">
               Learn More
            </div>
         </div>
      </div>
   </div>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
</template>

<script setup lang="ts">
import CountrySearchablePhoneField from '@/components/CountrySearchablePhoneField.vue'
import 'animate.css'
import { handleApiError, loadScript } from '@/utils/common'
import Admin from '@/composables/admin'
import { onMounted, ref } from 'vue'
import { NotificationType } from '@/types'
import Notification from '@/widgets/Notification.vue'
import { Page } from '@/types/page'
// @ts-ignore
import $ from 'jquery'

const props = defineProps(['title'])
const recaptchaError = ref<any>(null)
const { getPageDetails } = Admin()
const isLoading = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const page = ref<Page>()
const form = ref<any>({
   fullName: { value: '', error: false, errorMessage: '' },
   companyName: { value: '', error: false, errorMessage: '' },
   email: { value: '', error: false, errorMessage: '' },
   phone: { value: '', error: false, errorMessage: '' },
   password: { value: '', error: false, errorMessage: '' },
   message: { value: '', error: false, errorMessage: '' },
})
const passwordFieldtype = ref<'password' | 'text'>('password')

const togglePasswordFieldType = () => {
   passwordFieldtype.value = passwordFieldtype.value == 'password' ? 'text' : 'password'
}

const handlePhoneInput = (input: any) => {

   if (input === false) {

      form.value.phone.value = ''
      form.value.phone.error = true
      form.value.phone.errorMessage = 'Phone is required'
   } else {

      form.value.phone.value = input
      form.value.phone.error = false
   }
}

const handleButtonClick = () => {
   window.location.href = page.value?.bannerButtonUrl as string
}

const fetchPageDetails = async () => {
   const response = await getPageDetails(props.title)
   if (response.status == 200) {
      page.value = {
         title: response.data.page.title,
         bannerHeading: response.data.page.banner_heading,
         bannerSubHeading: response.data.page.banner_sub_heading,
         bannerInfoText: response.data.page.banner_text,
         bannerButtonText: response.data.page.banner_button_text,
         bannerButtonUrl: response.data.page.banner_button_url,
         sectionTitle: response.data.page.section_title,
         sectionInfoText: response.data.page.section_info_text,
         mediaType: response.data.page.media_type,
         mediaSource: response.data.page.media_source,
         titleSlug: response.data.page.title_slug,
         showSupplierForm: response.data.page.show_supplier_form,
      }
   } else {
      const errorMessage = handleApiError(response)
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: errorMessage}
   }
}

const notifClosed = () : void => {
   notif.value.show = false
}

onMounted(async () => {

   var recaptchaScriptUrl = 'https://www.google.com/recaptcha/api.js'
   $('.google-recaptcha').html('<div class="g-recaptcha" data-sitekey="6LdgLVspAAAAAH4Y109CAIfw26hm_onZFQsycv1v"></div>')
   loadScript(recaptchaScriptUrl, function () {})

   await fetchPageDetails()

   $('.slide-up').addClass('animate__slideInUp')
   $('.slide-down').addClass('animate__slideInDown')
})
</script>

<style scoped>
   
   .box-animate-one {
      animation-name: slideUpDown;
      animation-duration: 12s;
      animation-iteration-count: infinite;
   }
   
   .box-animate-two {
      animation-name: slideDownUp;
      animation-duration: 12s;
      animation-iteration-count: infinite;
   }

   @keyframes slideUpDown {
      0%, 100% {
         transform: translateY(-20%);
      } 50% {
         transform: translateY(20%);
      }
   }

   @keyframes slideDownUp {
      0%, 100% {
         transform: translateY(80%);
      } 
      50% {
         transform: translateY(30%);
      }
   }

</style>