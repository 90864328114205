<template>
   <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
         <div class="sm:flex-auto">
            <h1 class="text-base font-semibold leading-6 text-gray-900">Cars</h1>
            <p class="mt-2 text-sm text-gray-700">A list of all the agents in our database</p>
         </div>
      </div>
      <div class="mt-8 flow-root">
         <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="flex flex-col min-w-full py-2 align-middle sm:px-6 lg:px-8">
               <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <DataTable
                     ref="dt"
                     class="text-sm"
                     v-model:filters="filters" 
                     :value="agents"
                     paginator showGridlines :rows="10" 
                     dataKey="id"
                     filterDisplay="menu" 
                     :loading="loading"
                     :globalFilterFields="[]"
                  >
                     <template #header>
                        <div class="flex gap-2 w-full justify-end">
                           <span class="p-input-icon-left">
                              <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                           </span>
                           <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined @click="clearFilter()" />
                        </div>
                     </template>
                     <template #empty> No agents yet </template>
                     <template #loading> Loading agents data. Please wait. </template>
                     <Column field="name" header="Name">
                        <template #body="{ data }">
                           {{ data.name }}
                        </template>
                     </Column>
                     <Column field="email" header="Email">
                        <template #body="{ data }">
                           {{ data.email }}
                        </template>
                     </Column>

                     <Column field="commission" header="Commission (%)">
                        <template #body="{ data }">
                           {{ data.commission }}
                        </template>
                     </Column>

                     <Column field="loyalty.threshold" header="Loyalty Threshold">
                        <template #body="{ data }">
                           {{ data.loyalty.threshold }}
                        </template>
                     </Column>

                     <Column header="Actions" :exportable="false" class="w-[22rem]">
                        <template #body="{ data }">
                           <div class="flex items-center gap-3">
                              <button @click="handleEdit(data)" type="button" class="rounded rounded-bg bg-theme-button4-bg text-theme-button4-txt text-center w-[100px] py-2 uppercase text-xs">
                                 Edit Loyalty
                              </button>
                           </div>
                        </template>
                     </Column>
                  </DataTable> 
               </div>
            </div>
         </div>
      </div>
   </div>
   <SlideOver :show="openDialog" @closed="openDialog = false">
      <template v-slot:content>
         <SaveAgent :agent="editAgent" @success="handleSaveSuccess" @error="handleSaveError" />
      </template>
   </SlideOver>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { NotificationType } from '@/types/index'
import { Agent } from '@/types/admin'
import Admin from '@/composables/admin/index'
import SlideOver from '@/widgets/SlideOver.vue'
import Notification from '@/widgets/Notification.vue'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import SaveAgent from '@/components/admin/SaveAgent.vue'
import { MenuItems } from '@headlessui/vue'

const { allAgents } = Admin()
const agents = ref<Agent[] | null>(null)
const isLoading = ref<boolean>(false)
const openDialog = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const filters = ref()
const loading = ref<boolean>(false)
const dt = ref()
const editAgent = ref<Agent | null>(null)

onMounted(async () => {

   await fetchAgents()
})

const fetchAgents = async () => {

   const response = await allAgents()
   if (response.status == 200) {

      let items : Agent[] = []
      response.data.forEach((item: any) => {
         items.push({
            id: item.id,
            name: item.name,
            email: item.email,
            commission: item.commission,
            phone: item.phone,
            address: item.address,
            isParent: item.is_parent,
            loyalty: {
               redeemed: item.loyalty.redeemed,
               threshold: item.loyalty?.threshold,
            }
         })
      })

      agents.value = items
   }
}

const handleEdit = (data: Agent) => {

   editAgent.value = data
   openDialog.value = true
}

const handleSaveSuccess = async () => {
   openDialog.value = false
   notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'Agent has been saved successfully'}
   setTimeout(() => { notifClosed() }, 4000)
   
   await fetchAgents()
}

const handleSaveError = (error: string) => {
   notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
   setTimeout(() => { notifClosed() }, 4000)
}

const notifClosed = () : void => {
   notif.value.show = false
}

const initFilters = () => {
   filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
   }
}

initFilters()

const clearFilter = () => {
   initFilters()
}

const handleConfirmClose = () => openDialog.value = false

</script>