<template>
   <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div class="sm:mx-auto sm:w-full sm:max-w-sm">
         <img class="mx-auto h-10 w-auto" src="/assets/logo.png" alt="Vehitra World" />
         <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>
      </div>
      <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
         <form class="space-y-6" action="#" method="POST">
            <div>
               <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email address</label>
               <div class="mt-2">
                  <input v-model="email" type="email" autocomplete="email" class="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none" />
               </div>
            </div>
            <div>
               <div class="flex items-center justify-between">
                  <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
               </div>
               <div class="mt-2">
                  <input v-model="password" type="password" autocomplete="current-password" class="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none" />
               </div>
            </div>
            <div>
               <button @click="handleLogin" :class="isLoading ? 'cursor-not-allowed' : 'hover:bg-indigo-500'" type="button" class="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm outline-none">Sign in</button>
            </div>
         </form>

         <p class="mt-10 text-center text-sm text-gray-500">Not a member?{{ ' ' }}
            <a href="#" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Start a 14 day free trial</a>
         </p>
      </div>
   </div>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import AdminAuth from '@/composables/admin/Auth'
import { handleApiError, saveAdminAuthCredentials } from '@/utils/common'
import { NotificationType } from '@/types'
import { useRouter } from 'vue-router'
import Notification from '@/widgets/Notification.vue'

const email = ref<string>('')
const password = ref<string>('')
const isLoading = ref<boolean>(false)
const { login }  = AdminAuth()
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const router = useRouter()

const handleLogin = async () => {

   if (email.value != '' && password.value != '') {
      
      isLoading.value = true
      const response = await login(email.value, password.value)
      isLoading.value = false
      if (response.status == 200) {
         saveAdminAuthCredentials(response.data)
         router.push({name: 'AdminDashboard'})
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: error, messageDetails: ''}
         setTimeout(() => { notifClosed() }, 4000)
      }
   }
}

const notifClosed = () : void => {
   notif.value.show = false
}

</script>