<template>

   <div class="relative p-5" :class="layout == 'simple' ? 'col-span-7 md:col-span-2' : 'col-span-8'">
      <div class="flex flex-col space-y-2">
         <div :class="hasError ? 'text-theme-error' : 'text-theme-gray-2'" class="flex gap-2 uppercase text-small">
            <span>{{ label }} {{ searchMode == 'airport' ? '(airports only)' : '' }}</span>
            <span v-if="hasError">(required)</span>
         </div>
         <div class="flex flex-row items-center w-full">
            <input v-if="layout == 'simple'" v-model="address" @input="handleAddressSearch" type="text" placeholder="Enter Airport, Hotel or Address" class="flex-1 font-bold text-theme-gray-5 text-size-6 border-0 outline-none text-left pr-2" />
            <span v-else class="font-bold text-theme-gray-5 text-size-6">{{ address }}</span>
            <div v-if="isLoading" class="ml-auto">
               <LoaderIcon />
            </div>
            <div v-else-if="selectedAddress && layout == 'simple'" @click="handleClearInput" class="ml-auto">
               <font-awesome-icon size="lg" icon="fa-solid fa-times-circle" class="text-theme-active cursor-pointer" />
            </div>
            <div v-else-if="hasError" class="ml-auto">
               <font-awesome-icon size="1x" icon="fa-solid fa-circle-exclamation" class="text-theme-error" />
            </div>
         </div>
         <ul v-if="isAddressDropOpen && addresses.length" class="z-10 !mt-0 max-h-[200px] overflow-y-auto origin-top-right absolute top-full left-0 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5">
            <li @click="handleAddressSelect(item)" v-for="(item, index) in addresses" :key="index" class="flex flex-row items-start gap-2 py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer">
               <span v-if="item.isAirport" class="mt-1">
                  <font-awesome-icon size="1x" icon="fa-solid fa-plane" />
               </span>
               <span v-else class="mt-[0.13rem]">
                  <font-awesome-icon size="1x" icon="fa-solid fa-location-dot" />
               </span>
               <span>{{ item.name }}</span>
            </li>
         </ul>
      </div>
   </div>

</template>

<script setup lang="ts">
import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
import Location from '@/composables/Location'
import { handleApiError, sortPlacePredictions } from '@/utils/common'
import { GoogleLocationType } from '@/types/index'
import LoaderIcon from '@/components/LoaderIcon.vue'

const { searchLocation, searchLocality } = Location()
const props = defineProps(['drive', 'layout', 'data', 'label', 'type', 'hasError'])
const emit = defineEmits(['addressSelected', 'addressEmpty'])
const error = ref<string | false>(false)
const isLoading = ref<boolean>(false)
const address = ref<string>('')
const isAddressDropOpen = ref<boolean>(false)
const addresses = ref<GoogleLocationType[]>([])
const selectedAddress = ref<GoogleLocationType | null>(null)
const SEARCH_DELAY = 400
let searchTimeout: ReturnType<typeof setTimeout> | null = null
const searchMode = ref<'airport' | 'full'>('full')

const handleAddressSelect = (value: GoogleLocationType) => {
   address.value = value.name
   selectedAddress.value = value
   isAddressDropOpen.value = false
}

const handleAddressSearch = async () => {
   const addr = address.value.toLowerCase().trim()

   if (addr.length > 2) {

      if (searchTimeout) {
         clearTimeout(searchTimeout);
      }

      searchTimeout = setTimeout(async () => {

         isLoading.value = true
         const response = searchMode.value == 'airport' ? await searchLocality(addr, 'airport') : await searchLocation(addr)
         isLoading.value = false

         if (response.status == 200) {

            let adds : GoogleLocationType[] = []
            if (searchMode.value == 'full') {

               const predictions = sortPlacePredictions(response.data.data)
               predictions.forEach((item: any) => {
                  adds.push({
                     id: item.id,
                     name: item.name,
                     isAirport: item.isAirport,
                     coordinates: {lat: null, lng: null}
                  })
               })
            } else {
               response.data.data.forEach((item: any) => {
                  adds.push({
                     id: item.id,
                     name: item.name,
                     isAirport: true,
                     coordinates: {lat: null, lng: null}
                  })
               })
            }

            addresses.value = adds
            isAddressDropOpen.value = adds.length ? true : false
         } else {
            error.value = handleApiError(response)
         }
         
      }, SEARCH_DELAY)

   }
}

watch(selectedAddress, (newValue, oldValue) => {
  emit('addressSelected', newValue, props.type)
})

watch(() => props.drive, (newValue, oldValue) => {
   //searchMode.value = newValue == 'distance' && props.type == 'pickUp' ? 'airport' : 'full'
   searchMode.value = 'full'
})

watch(address, (newValue, oldValue) => {

   if (newValue === '') {
      emit('addressSelected', null, props.type)
   }
})

const listener = (event: any) => {
   isAddressDropOpen.value = false
}

const handleClearInput = () => {
   selectedAddress.value = null
   address.value = ''
}

onMounted(() => {
   document.addEventListener('click', listener)

   if (props.data) {
      selectedAddress.value = {
         id: props.data.location.id,
         name: props.data.location.name,
         isAirport: props.data.location.isAirport,
         coordinates: {lat: null, lng: null}
      }

      address.value = props.data.location.name
   }
})

onBeforeUnmount(() => {
   document.removeEventListener('click', listener)
})

</script>