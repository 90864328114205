import { DriveType, GoogleLocationType, Locality, LocationSchedule, UserSearch } from "@/types";
import { getSessionPrefix } from "@/utils/common";


export function encodeIntoQuery(driveType: DriveType, pickUp: LocationSchedule, dropOff: LocationSchedule, hours: number | null): string {
   
   const queryParams = new URLSearchParams();
 
   queryParams.append("drive", driveType);
   queryParams.append("pick-up", pickUp ? pickUp.location.name : '')
   queryParams.append("pick-up-id", pickUp ? pickUp.location.id : '')
   queryParams.append("pick-up-coordinates", encodeCoordinates(pickUp.location))
   queryParams.append("pick-up-type", pickUp && pickUp.location.isAirport ? 'airport' : '')
   queryParams.append("pick-up-date", pickUp ? pickUp.date as string : '')
   queryParams.append("pick-up-time", pickUp ? pickUp.time as string : '')

   if (driveType == 'distance') {

      queryParams.append("drop-off", dropOff ? dropOff.location.name : '')
      queryParams.append("drop-off-id", dropOff ? dropOff.location.id : '')
      queryParams.append("drop-off-coordinates", encodeCoordinates(dropOff.location))
      queryParams.append("drop-off-type", dropOff && dropOff.location.isAirport ? 'airport' : '')
   } else if (driveType == 'hourly') {

      queryParams.append("hours", hours ? hours.toString() : '')
   }
 
   return queryParams.toString();
}

export function decodeFromQuery(queryString: string) : any {

   let pickUp : LocationSchedule
   let dropOff : LocationSchedule | null
   let hours : number | null = null
   let driveType : DriveType

   const params = queryString.split('&').reduce((paramsObject:any, param) => {

      const [key, value] = param.split('=');
      paramsObject[key] = decodeURIComponent(value).replaceAll("+", " ");
      return paramsObject;
   }, {});

   const pickUpCoords = decodeCoordinates(params['pick-up-coordinates'])
   const dropOffCoords = decodeCoordinates(params['drop-off-coordinates'])

   driveType = params['drive'] as DriveType

   pickUp = {
      location: {
         id: params['pick-up-id'],
         name: params['pick-up'],
         isAirport: params['pick-up-type'] == 'airport' ? true : false,
         coordinates: pickUpCoords,
      },
      date: params['pick-up-date'],
      time: params['pick-up-time']
   }

   if(params['drive'] == 'distance'){

      dropOff = {
         location: {
            id: params['drop-off-id'],
            name: params['drop-off'],
            isAirport: params['drop-off-type'] == 'airport' ? true : false,
            coordinates: dropOffCoords
         }
      }
   } else {
      dropOff = null
   }

   if (params['hours'] && params['hours'] != '') {
      hours = parseInt(params['hours'])
   }

   return {
      driveType,
      pickUp,
      dropOff, 
      hours
   }
}

export function saveUserSearch(search: UserSearch) : void {
   const prefix = getSessionPrefix()
   localStorage.setItem(`${prefix}_user_search`, JSON.stringify(search))
}

export function retrieveUserSearch() : UserSearch {
   const prefix = getSessionPrefix()
   return JSON.parse(localStorage.getItem(`${prefix}_user_search`) as string) as UserSearch
}

export function saveUserTempSearch(search: UserSearch) : void {
   const prefix = getSessionPrefix()
   localStorage.setItem(`${prefix}_temp_user_search`, JSON.stringify(search))
}

export function retrieveUserTempSearch() : any {
   const prefix = getSessionPrefix()
   const search = localStorage.getItem(`${prefix}_temp_user_search`)
   return search ? JSON.parse(search) : null
}

export function deleteUserTempSearch() : void {
   const prefix = getSessionPrefix()
   localStorage.removeItem(`${prefix}_temp_user_search`)
}

const encodeCoordinates = (address: GoogleLocationType) : string => {

   let encode = ''
   if (address && address.coordinates.lat !== null && address.coordinates.lng !== null) {
      encode += address.coordinates.lat + '_' + address.coordinates.lng
   }

   return encode
}

const decodeCoordinates = (input: string) : {lat: number | null, lng: number | null} => {

   const parts = input ? input.split('_') : null
   return { lat: parts ? parseInt(parts[0]) : null, lng: parts ? parseInt(parts[1]) : null }
}