<template>
   <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
         <div class="sm:flex-auto">
            <h1 class="text-base font-semibold leading-6 text-gray-900">Pages</h1>
            <p class="mt-2 text-sm text-gray-700">A list of all the landing pages created in our database</p>
         </div>
         <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button @click="handleAddPage" type="button" class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
               Add Page
            </button>
         </div>
      </div>
      <div class="mt-8 flow-root">
         <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="flex flex-col min-w-full py-2 align-middle sm:px-6 lg:px-8">
               <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <DataTable
                     exportFilename="pages"
                     ref="dt"
                     class="text-sm"
                     v-model:filters="filters" 
                     :value="pages"
                     paginator showGridlines :rows="10" 
                     dataKey="id"
                     filterDisplay="menu" 
                     :loading="loading"
                     :globalFilterFields="['title']"
                  >
                     <template #header>
                        <div class="flex gap-2 w-full justify-end">
                           <span class="p-input-icon-left">
                              <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                           </span>
                           <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined @click="clearFilter()" />
                           <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
                        </div>
                     </template>
                     <template #empty> No pages added yet </template>
                     <template #loading> Loading bookings data. Please wait. </template>
                     <Column field="title" header="Title">
                        <template #body="{ data }">
                           {{ data.title }}
                        </template>
                     </Column>
                     <Column field="banner_heading" header="Heading">
                        <template #body="{ data }">
                           {{ data.banner_heading }}
                        </template>
                     </Column>
                     <Column field="banner_sub_heading" header="Sub Heading">
                        <template #body="{ data }">
                           {{ data.banner_sub_heading }}
                        </template>
                     </Column>
                     <Column field="created_at" header="Created At">
                        <template #body="{ data }">
                           {{ data.created_at }}
                        </template>
                     </Column>
                     <Column header="Actions" :exportable="false" class="w-[22rem]">
                        <template #body="{ data }">
                           <div class="flex items-center gap-3">
                              <button @click="handleViewPage(data)" type="button" class="rounded rounded-bg bg-theme-button2-bg text-theme-button2-txt text-center w-[100px] py-2 uppercase text-xs">
                                 View
                              </button>
                              <button @click="handleEditPage(data)" type="button" class="rounded rounded-bg bg-theme-button4-bg text-theme-button4-txt text-center w-[100px] py-2 uppercase text-xs">
                                 Edit
                              </button>
                              <button @click="handleDeletePage(data.id)" type="button" class="rounded rounded-bg bg-theme-button5-bg text-theme-button5-txt text-center w-[100px] py-2 uppercase text-xs">
                                 Delete
                              </button>
                           </div>
                        </template>
                     </Column>
                  </DataTable> 
               </div>
            </div>
         </div>
      </div>
   </div>
   <SlideOver :show="openSaveDialog" @closed="openSaveDialog = false">
      <template v-slot:content>
         <SavePage :page="editPage" @success="handleSavePageSuccess" @error="handleSavePageError" />
      </template>
   </SlideOver>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
   <ConfirmationModal :show="openConfirmModal" @closed="handleConfirmClose">
      <template v-slot:buttons>
         <button @click="askingConfirmed" type="button" :class="isLoading ? 'opacity-60 cursor-not-allowed' : 'hover:bg-red-500 cursor-pointer'" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm xs:ml-3 xs:w-auto">Yes, delete</button>
         <button @click="openConfirmModal = false" type="button" class="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 xs:mt-0 xs:w-auto">Cancel</button>
      </template>
   </ConfirmationModal>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import SlideOver from '@/widgets/SlideOver.vue'
import Notification from '@/widgets/Notification.vue'
import ConfirmationModal from '@/widgets/ConfirmationModal.vue'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import { NotificationType } from '@/types/index'
import { Page } from '@/types/page'
import Admin from '@/composables/admin'
import { handleApiError } from '@/utils/common'
import SavePage from '@/components/admin/SavePage.vue'
import { useRouter } from 'vue-router'

const isLoading = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const filters = ref()
const loading = ref<boolean>(false)
const dt = ref()
const openConfirmModal = ref<boolean>(false)
const openSaveDialog = ref<boolean>(false)
const pages = ref<Page[] | null>(null)
const deleteItem = ref<number>(0)
const editPage = ref<Page | null>(null)
const router = useRouter()
const { allPages, removePage } = Admin()

const initFilters = () => {
   filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
   }
}

const handleConfirmClose = () => openConfirmModal.value = false

const notifClosed = () : void => {
   notif.value.show = false
}

initFilters()

const clearFilter = () => {
   initFilters()
}

const exportCSV = (event: any) => {
   dt.value.exportCSV()
}

const askingConfirmed = async () => {
   if (!isLoading.value && deleteItem.value) {
      isLoading.value = true
      const response = await removePage(deleteItem.value)

      if (response.status == 204) {
         await fetchPages()
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
         setTimeout(() => { notifClosed() }, 4000)
      }

      isLoading.value = false
      openConfirmModal.value = false
   }  
}

const handleAddPage = () => {
   editPage.value = null
   openSaveDialog.value = true
}

const handleEditPage = (data: any) => {
   editPage.value = data
   openSaveDialog.value = true
}

const handleViewPage = (data: any) => {
   const routeUrl = router.resolve({name: 'CustomPage', params: { title: data.title_slug } }).href
   window.open(routeUrl, '_blank')
}

const handleSavePageSuccess = async () => {
   openSaveDialog.value = false
   notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'The page has been added successfully'}
   setTimeout(() => { notifClosed() }, 4000)
   
   await fetchPages()
}

const handleSavePageError = (error: string) => {
   notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
   setTimeout(() => { notifClosed() }, 4000)
}

const handleDeletePage = (id: number) => {
   deleteItem.value = id
   openConfirmModal.value = true
}

const fetchPages = async () => {

   isLoading.value = true
   const response = await allPages()
   isLoading.value = false

   if (response.status == 200) {
      pages.value = response.data
   } else {
      const error = handleApiError(response)
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
      setTimeout(() => { notifClosed() }, 4000)
   }
}

onMounted(() => {

   fetchPages()
})

</script>