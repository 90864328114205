import { getUserToken, getBackendBaseUrl } from "@/utils/common"
import { LocationSchedule, Car as CarData, UserSearch, PaymentDetails } from '@/types/index'
import axios from "axios"

const baseUrl : string = getBackendBaseUrl()

const ApplePay = () => {

   const getHeaders = () => { 
      return {
         'Content-Type': 'application/json',
      }
   }

   const validateMerchant = async (url: string) : Promise<any> => {
      
      try {
         const response = await axios.post(baseUrl + 'api/apple-pay/validate-merchant', {validationURL: url}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }
   
   const authorizePayment = async (token: string, orderId: string, contactDetails: any) : Promise<any> => {
      
      try {
         const response = await axios.post(baseUrl + 'api/apple-pay/authorize-payment', {paymentToken: token, order: orderId, contact: contactDetails}, {headers: getHeaders()})
         return response
      } catch (err : any) {
         return err
      }
   }

   return {
      validateMerchant,
      authorizePayment
   }
}

export default ApplePay