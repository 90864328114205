<template>

   <Dashboard :user="adminAuth.admin" :breadcrump="breadcrump" />
   
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { isAdminLoggedIn, isLoggedIn } from '@/utils/common'
import Dashboard from '@/components/account/dashboard.vue'
import { Nav } from '@/types/admin'

const adminAuth = isAdminLoggedIn()
const breadcrump = ref<Nav[] | null>([
   { title: 'Admin Dashboard', link: null }
])

</script>