
import Car from "@/composables/Car"
import { Locality, UserSearch, Car as CarData } from "@/types"
import { getSessionPrefix, handleApiError } from "@/utils/common"

const { searchCarsChauffer, searchCarsTransfer }  = Car()

const CarService = () => {

   const getCars = async (search : UserSearch) => {
      try {

         if (search.type == 'hourly') {

            const response = await searchCarsChauffer(search.pickUp)

            if (response.status === 200) {
               return { success: true, data: response.data }
            } else {
               return { success: false, error: handleApiError(response) }
            }
         } else if (search.type == 'distance') {

            const response = await searchCarsTransfer(search.pickUp, search.dropOff)

            if (response.status === 200) {
               return { success: true, data: response.data }
            } else {
               return { success: false, error: handleApiError(response) }
            }
         } else {
            return null
         }
      } catch (err) {
         return { success: false, error: err }
      }
   }

   const saveSelectedCar = (car: CarData) : void => {
      const prefix = getSessionPrefix()
      localStorage.setItem(`${prefix}_selected_car`, JSON.stringify(car))
   }
   
   const retrieveUserSelectedCar = () : CarData => {
      const prefix = getSessionPrefix()
      return JSON.parse(localStorage.getItem(`${prefix}_selected_car`) as string) as CarData
   }

   return {
      getCars,
      saveSelectedCar,
      retrieveUserSelectedCar
   }
}

export default CarService
