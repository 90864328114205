<template>
   <div class="py-10 px-4 sm:px-6 lg:px-24">
      <div class="sm:flex sm:items-center">
         <div class="flex sm:flex-auto">
            <div class="flex flex-col">
               <h1 class="text-base font-semibold leading-6 text-gray-900">{{ car ? car.name : '' }}</h1>
               <p class="mt-2 text-sm text-gray-700">View all the locations added for this car</p>
            </div>
            <div class="flex flex-col gap-4 ml-auto">
               <button @click="handleAddCarLocation()" type="button" class="block rounded-md bg-theme-active px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                  Add Location
               </button>
            </div>
         </div>
      </div>
      <div class="mt-8 flow-root">
         <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="flex flex-col min-w-full py-2 align-middle sm:px-6 lg:px-8">
               <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <DataTable
                     exportFilename="bookings"
                     ref="dt"
                     class="text-sm"
                     v-model:filters="filters" 
                     :value="locations"
                     paginator showGridlines :rows="10" 
                     dataKey="id"
                     filterDisplay="menu" 
                     :loading="loading"
                     :globalFilterFields="['locality.name']"
                  >
                     <template #header>
                        <div class="flex items-center justify-start">
                           <div class="flex items-center gap-3">
                              <div class="font-normal text-sm text-gray-700">Currently showing {{ locationType }} locations</div>
                              <Switch v-model="enabled" class="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full outline-none">
                                 <span class="sr-only">Use setting</span>
                                 <span aria-hidden="true" class="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                                 <span aria-hidden="true" :class="[enabled ? 'bg-indigo-600' : 'bg-gray-200', 'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out']" />
                                 <span aria-hidden="true" :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out']" />
                              </Switch>
                           </div>
                           <div class="flex gap-2 ml-auto">
                              <span class="p-input-icon-left">
                                 <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                              </span>
                              <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined @click="clearFilter()" />
                              <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
                           </div>
                        </div>
                     </template>
                     <template #empty> No locations added yet </template>
                     <template #loading> Loading bookings data. Please wait. </template>
                     <Column field="locality.name" header="Name">
                        <template #body="{ data }">
                           {{ data.locality.name }}
                        </template>
                     </Column>
                     <Column field="locality.type" header="Type" exportHeader="Type" class="w-[14rem]">
                        <template #body="{ data }">
                           <div class="rounded rounded-bg bg-theme-button1-bg text-theme-button1-txt text-center w-[100px] py-2 uppercase text-xs">
                              {{ data.locality.type }}
                           </div>
                        </template>
                     </Column>
                     <Column v-if="locationType == 'chauffer'" field="rate_per_hour" header="Rate">
                        <template #body="{ data }">
                           {{ data.rate_per_hour }}
                        </template>
                     </Column>
                     <Column v-if="locationType == 'transfer'" field="rate" header="Rate">
                        <template #body="{ data }">
                           {{ data.rate }}
                        </template>
                     </Column>
                     <Column header="Actions" :exportable="false" class="w-[14rem]">
                        <template #body="{ data }">
                           <button @click="handleDelete(data.id)" type="button" class="rounded rounded-bg bg-theme-button5-bg text-theme-button5-txt text-center w-[100px] py-2 uppercase text-xs">
                              Delete
                           </button>
                        </template>
                     </Column>
                  </DataTable> 
               </div>
            </div>
         </div>
      </div>
   </div>
   <SlideOver :show="openLocationDialog" @closed="openLocationDialog = false">
      <template v-slot:content>
         <SaveCarLocation :carId="id" :locationType="locationType" @success="handleSaveCarLocationSuccess" @error="handleSaveCarLocationError" />
      </template>
   </SlideOver>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
   <ConfirmationModal :show="openConfirmModal" @closed="handleConfirmClose">
      <template v-slot:buttons>
         <button @click="askingConfirmed" type="button" :class="isLoading ? 'opacity-60 cursor-not-allowed' : 'hover:bg-red-500 cursor-pointer'" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm xs:ml-3 xs:w-auto">Yes, delete</button>
         <button @click="openConfirmModal = false" type="button" class="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 xs:mt-0 xs:w-auto">Cancel</button>
      </template>
   </ConfirmationModal>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { Car, CarLocation, NotificationType } from '@/types/index'
import Admin from '@/composables/admin/index'
import { handleApiError } from '@/utils/common'
import SaveCarLocation from '@/components/admin/SaveCarLocation.vue'
import Notification from '@/widgets/Notification.vue'
import SlideOver from '@/widgets/SlideOver.vue'
import { FilterMatchMode } from 'primevue/api'
import { Switch } from '@headlessui/vue'
import ConfirmationModal from '@/widgets/ConfirmationModal.vue'

const props = defineProps(['id'])
const locations = ref<CarLocation[] | null>(null)
const { carLocations, removeCarLocation } = Admin()
const isLoading = ref<boolean>(false)
const car = ref<Car | null>(null)
const openLocationDialog = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const locationType = ref<'chauffer' | 'transfer'>('chauffer')
const filters = ref()
const loading = ref<boolean>(false)
const dt = ref()
const enabled = ref<boolean>(true)
const openConfirmModal = ref<boolean>(false)
const deleteItem = ref<number>(0)

onMounted(async () => {

   await fetchCarLocations()  
})

const handleConfirmClose = () => openConfirmModal.value = false

const fetchCarLocations = async () => {
   isLoading.value = true
   const response = await carLocations(props.id, locationType.value)
   isLoading.value = false
   if (response.status == 200) {
      
      locations.value = response.data.locations
      car.value = response.data.car
   } else {
      const error = handleApiError(response)
      console.log(error)
   }
}

const handleAddCarLocation = async () => {
   openLocationDialog.value = true
}

const notifClosed = () : void => {
   notif.value.show = false
}

const handleSaveCarLocationSuccess = async () => {
   openLocationDialog.value = false
   notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'The location has been attached to the car successfully'}
   setTimeout(() => { notifClosed() }, 4000)
   
   await fetchCarLocations()
}

const handleSaveCarLocationError = async (error: string) => {
   openLocationDialog.value = false
   notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
   setTimeout(() => { notifClosed() }, 4000)
}

const handleDelete = (id: number) => {

   deleteItem.value = id
   openConfirmModal.value = true
}

const askingConfirmed = async () => {
if (!isLoading.value && deleteItem.value) {
   isLoading.value = true
   const response = await removeCarLocation(deleteItem.value, locationType.value)

   if (response.status == 204) {
      await fetchCarLocations()
   } else {
      const error = handleApiError(response)
      notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
      setTimeout(() => { notifClosed() }, 4000)
   }

   isLoading.value = false
   openConfirmModal.value = false
}  
}

const initFilters = () => {
   filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
   }
}

initFilters()

const clearFilter = () => {
   initFilters()
}

const exportCSV = (event: any) => {
   dt.value.exportCSV()
}

watch(enabled, (newValue, oldValue) => {

   locationType.value = newValue ? 'chauffer' : 'transfer'
   fetchCarLocations().then(() => {})
})

</script>