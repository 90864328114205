<template>
   <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
         <div class="sm:flex-auto">
            <h1 class="text-base font-semibold leading-6 text-gray-900">Locations</h1>
            <p class="mt-2 text-sm text-gray-700">A list of all car locations added in our database</p>
         </div>
         <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button @click="handleAddCar()" type="button" class="block rounded-md bg-theme-active px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
               Add Location
            </button>
         </div>
      </div>
      <div class="mt-8 flow-root">
         <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
               <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-300">
                     <thead class="bg-gray-50">
                        <tr>
                           <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Name</th>
                           <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Type</th>
                           <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Country</th>
                           <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Bounds</th>
                           <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                              <span class="sr-only">Edit</span>
                           </th>
                        </tr>
                     </thead>
                     <tbody class="divide-y divide-gray-200 bg-white">
                        <tr v-for="(location, index) in locations" :key="index">
                           <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ location.name }}</td>
                           <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              <span class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">{{ location.type }}</span>
                           </td>
                           <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ location.country }}</td>
                           <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ location.bounds }}</td>
                           <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"> 
                              <a href="#" class="text-indigo-600 hover:text-indigo-900">Delete</a>
                           </td>
                        </tr>
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      </div>
   </div>
   <SlideOver :show="openSaveDialog" @closed="openSaveDialog = false">
      <template v-slot:content>
         <SaveLocation mode="add" @success="handleSaveSuccess" @error="handleSaveError" />
      </template>
   </SlideOver>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { Locality, NotificationType } from '@/types/index'
import Admin from '@/composables/admin/index'
import { handleApiError } from '@/utils/common'
import SlideOver from '@/widgets/SlideOver.vue'
import SaveLocation from '@/components/admin/SaveLocation.vue'
import Notification from '@/widgets/Notification.vue'

const locations = ref<Locality[] | null>(null)
const { allLocations } = Admin()
const isLoading = ref<boolean>(false)
const openSaveDialog = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const locationType = ref<'chauffer' | 'transfer'>('chauffer')

onMounted(async () => {

   await fetchLocations()  
})

const fetchLocations = async () => {
   isLoading.value = true
   const response = await allLocations(locationType.value)
   isLoading.value = false
   if (response.status == 200) {
      
      locations.value = response.data
   } else {
      const error = handleApiError(response)
      console.log(error)
   }
}

const handleAddCar = () => {
   openSaveDialog.value = true
}

const handleSaveSuccess = async () => {
   openSaveDialog.value = false
   notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'Your car has been added successfully'}
   setTimeout(() => { notifClosed() }, 4000)
   
   await fetchLocations()
}

const handleSaveError = (error: string) => {
   notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
   setTimeout(() => { notifClosed() }, 4000)
}

const notifClosed = () : void => {
   notif.value.show = false
}

</script>