<template>
   <Main v-if="layout == 'web'">
      <template v-slot:header>
         <Header />
      </template>

      <template v-slot:content>
         <router-view />
      </template>

      <template v-slot:footer>
         <Footer />
      </template>
   </Main>
   <Account v-else-if="layout == 'account' || (layout == 'admin' && ($route.name != 'AdminLogin'))">
      <template v-slot:content>
         <router-view />
      </template>
   </Account>
   <div v-else>
      <router-view />
   </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import Main from '@/layouts/Main.vue'
import Account from '@/layouts/Account.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const layout = computed(() => {
   return route.meta.layout
})

</script>
