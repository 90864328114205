<template>

   <div v-if="!isLoading" class="my-0 md:w-[50%] mx-auto">
      <div ref="pdfContent" class="px-6 mx-auto">
         <Invoice :data="data" />
      </div>
      <div class="flex flex-row items-center gap-3 justify-end">
         <button @click="downloadPDF" type="button" class="rounded-md bg-theme-button2-bg px-2.5 py-1.5 text-sm font-semibold text-theme-button2-txt shadow-sm">
            Download PDF
         </button>
      </div>
   </div>
   <ConfirmationModal :show="openConfirmModal" @closed="handleConfirmClose">
      <template v-slot:buttons>
         <button type="button" :class="isLoading ? 'opacity-60 cursor-not-allowed' : 'hover:bg-red-500 cursor-pointer'" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm xs:ml-3 xs:w-auto">Yes, proceed</button>
         <button @click="openConfirmModal = false" type="button" class="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 xs:mt-0 xs:w-auto">Cancel</button>
      </template>
   </ConfirmationModal>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import Invoice from '@/components/booking/Invoice.vue'
import { Booking } from '@/types'
import BookingService from '@/services/booking-service'
import { getSessionPrefix } from '@/utils/common'
import router from '@/router'
// @ts-ignore
import html2pdf from 'html2pdf.js'
import ConfirmationModal from '@/widgets/ConfirmationModal.vue'

const props = defineProps(['number'])
const data = ref<Booking | null>(null)
const isLoading = ref<boolean>(true)
const { readBooking } = BookingService()
const pdfContent = ref(null)
const openConfirmModal = ref<boolean>(false)

onMounted(async () => {
   
   const prefix = getSessionPrefix()

   const response = await readBooking(props.number)
   isLoading.value = false
   
   if (response.success) {
      data.value = response.data.data
   } else {
      console.log(response.error)
   }
})

const downloadPDF = () => {

   const content = pdfContent.value
   html2pdf(content).from(content).save()
}

const handleCancelBooking = () => {

   openConfirmModal.value = true
}

const handleMakePayment = () => {

   openConfirmModal.value = true
}

const handleConfirmClose = () => openConfirmModal.value = false

</script>