<template>
   <div class="px-4 sm:px-6 lg:px-8">
      <div class="sm:flex sm:items-center">
         <div class="sm:flex-auto">
            <h1 class="text-base font-semibold leading-6 text-gray-900">Cars</h1>
            <p class="mt-2 text-sm text-gray-700">A list of all the cars created in our database</p>
         </div>
         <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button @click="handleAddCar()" type="button" class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
               Add Car
            </button>
         </div>
      </div>
      <div class="mt-8 flow-root">
         <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="flex flex-col min-w-full py-2 align-middle sm:px-6 lg:px-8">
               <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <DataTable
                     exportFilename="cars"
                     ref="dt"
                     class="text-sm"
                     v-model:filters="filters" 
                     :value="cars"
                     paginator showGridlines :rows="10" 
                     dataKey="id"
                     filterDisplay="menu" 
                     :loading="loading"
                     :globalFilterFields="['name', 'type.name', 'supplier.name']"
                  >
                     <template #header>
                        <div class="flex gap-2 w-full justify-end">
                           <span class="p-input-icon-left">
                              <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                           </span>
                           <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined @click="clearFilter()" />
                           <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
                        </div>
                     </template>
                     <template #empty> No locations added yet </template>
                     <template #loading> Loading bookings data. Please wait. </template>
                     <Column field="name" header="Name">
                        <template #body="{ data }">
                           {{ data.name }}
                        </template>
                     </Column>
                     <Column field="type.name" header="Type" class="w-[12rem]">
                        <template #body="{ data }">
                           <div class="rounded rounded-bg text-center min-w-[85px] py-2 uppercase text-xs bg-theme-button4-bg text-theme-button4-txt">
                              {{ data.type.name }}
                           </div>
                        </template>
                        <template #filter="{ filterModel }">
                           <Dropdown v-model="filterModel.value" :options="(carsTypeOptions as string[])" placeholder="Select One" class="p-column-filter" showClear>
                                 <template #option="slotProps">
                                    {{ slotProps.option }}
                                 </template>
                           </Dropdown>
                        </template>
                     </Column>
                     <Column field="supplier.name" header="Supplier">
                        <template #body="{ data }">
                           {{ data.supplier.name }}
                        </template>
                        <template #filter="{ filterModel }">
                           <Dropdown v-model="filterModel.value" :options="(supplierOptions as string[])" placeholder="Select One" class="p-column-filter" showClear>
                                 <template #option="slotProps">
                                    {{ slotProps.option }}
                                 </template>
                           </Dropdown>
                        </template>
                     </Column>

                     <Column field="supplier.name" header="Carimage">
                        <template #body="{ data }">
                           <img class="rounded-lg" :src="data.image && data.image != '' ? data.image : '/assets/nocarfound.jpeg'" alt="" width="150">
                        </template>
                     </Column>


                     <Column header="Actions" :exportable="false" class="w-[22rem]">
                        <template #body="{ data }">
                           <div class="flex items-center gap-3">
                              <button @click="handleEditCar(data)" type="button" class="rounded rounded-bg bg-theme-button4-bg text-theme-button4-txt text-center w-[100px] py-2 uppercase text-xs">
                                 Edit
                              </button>
                              <button @click="$router.push({name: 'AdminCarLocation', params: {id: data.id}})" type="button" class="rounded rounded-bg bg-theme-button1-bg text-theme-button1-txt text-center w-[100px] py-2 uppercase text-xs">
                                 Locations
                              </button>
                              <button @click="handleDeleteCar(data.id)" type="button" class="rounded rounded-bg bg-theme-button5-bg text-theme-button5-txt text-center w-[100px] py-2 uppercase text-xs">
                                 Delete
                              </button>
                           </div>
                        </template>
                     </Column>
                  </DataTable> 
               </div>
            </div>
         </div>
      </div>
   </div>
   <SlideOver :show="openSaveDialog" @closed="openSaveDialog = false">
      <template v-slot:content>
         <SaveCar :car="editCar" :carTypes="carTypes" :suppliers="suppliers" @success="handleSaveCarSuccess" @error="handleSaveCarError" />
      </template>
   </SlideOver>
   <SlideOver :show="openLocationDialog" @closed="openLocationDialog = false">
      <template v-slot:content>
         <SaveCarLocation />
      </template>
   </SlideOver>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
   <ConfirmationModal :show="openConfirmModal" @closed="handleConfirmClose">
      <template v-slot:buttons>
         <button @click="askingConfirmed" type="button" :class="isLoading ? 'opacity-60 cursor-not-allowed' : 'hover:bg-red-500 cursor-pointer'" class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm xs:ml-3 xs:w-auto">Yes, delete</button>
         <button @click="openConfirmModal = false" type="button" class="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 xs:mt-0 xs:w-auto">Cancel</button>
      </template>
   </ConfirmationModal>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { Car, CarType, NotificationType, Supplier } from '@/types/index'
import Admin from '@/composables/admin/index'
import { handleApiError, logout } from '@/utils/common'
import SlideOver from '@/widgets/SlideOver.vue'
import SaveCar from '@/components/admin/SaveCar.vue'
import SaveCarLocation from '@/components/admin/SaveCarLocation.vue'
import Notification from '@/widgets/Notification.vue'
import ConfirmationModal from '@/widgets/ConfirmationModal.vue'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import router from '@/router'

const { allCarTypes, allSuppliers, createCar, removeCar } = Admin()
const cars = ref<Car[] | null>(null)
const { allCars } = Admin()
const isLoading = ref<boolean>(false)
const openSaveDialog = ref<boolean>(false)
const openLocationDialog = ref<boolean>(false)
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})
const filters = ref()
const loading = ref<boolean>(false)
const dt = ref()
const carTypes = ref<CarType[] | null>(null)
const suppliers = ref<Supplier[] | null>(null)
const carsTypeOptions = ref<string[] | null | undefined>(null)
const supplierOptions = ref<string[] | null | undefined>(null)
const openConfirmModal = ref<boolean>(false)
const deleteItem = ref<number>(0)
const editCar = ref<CarType | null>(null)

onMounted(async () => {

   await fetchCars()  

   const response = await allCarTypes()
   if (response.status == 200) {
      carTypes.value = response.data
      carsTypeOptions.value = carTypes.value?.map((carType: any) => carType.name as string)
   }

   const responsee = await allSuppliers()
   if (responsee.status == 200) {
      suppliers.value = responsee.data
      supplierOptions.value = suppliers.value?.map((supplier: any) => supplier.name as string)
   }
})

const fetchCars = async () => {
   isLoading.value = true
   const response = await allCars()
   isLoading.value = false
   if (response.status == 200) {
      
      cars.value = response.data
   } else if (response.response && response.response.status == 401) {
      logout(router)
   }
}

const handleEditCar = (data: CarType) => {

   editCar.value = data
   openSaveDialog.value = true
}

const handleAddCar = () => {
   editCar.value = null
   openSaveDialog.value = true
}

const handleAddCarLocation = () => {
   openLocationDialog.value = true
}

const handleSaveCarSuccess = async () => {
   openSaveDialog.value = false
   notif.value = { show: true, type: 'success', message: 'Success', messageDetails: 'Your car has been added successfully'}
   setTimeout(() => { notifClosed() }, 4000)
   
   await fetchCars()
}

const handleSaveCarError = (error: string) => {
   notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
   setTimeout(() => { notifClosed() }, 4000)
}

const notifClosed = () : void => {
   notif.value.show = false
}

const initFilters = () => {
   filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "type.name": { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "supplier.name": { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
   }
}

initFilters()

const clearFilter = () => {
   initFilters()
}

const exportCSV = (event: any) => {
   dt.value.exportCSV()
}

const handleConfirmClose = () => openConfirmModal.value = false

const handleDeleteCar = (id: number) => {

   deleteItem.value = id
   openConfirmModal.value = true
}

const askingConfirmed = async () => {
   if (!isLoading.value && deleteItem.value) {
      isLoading.value = true
      const response = await removeCar(deleteItem.value)

      if (response.status == 204) {
         await fetchCars()
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: 'Error', messageDetails: error}
         setTimeout(() => { notifClosed() }, 4000)
      }

      isLoading.value = false
      openConfirmModal.value = false
   }  
}

</script>