<template>

   <div class="relative">
      <div class="flex flex-col space-y-1">
         <div class="inline-block text-left cursor-pointer" @click="toggleDropdown">
            <button type="button" class="font-bold text-theme-gray-5 text-size-6 border-0 outline-none">{{ selectedItem.title }}</button>
            <ul v-if="isDropOpen" class="z-10 max-h-[200px] overflow-y-auto origin-top-right absolute right-0 mt-4 w-full shadow-lg bg-white ring-1 ring-black ring-opacity-5">
               <li v-for="(item, index) in data" :key="index" @click="selectedItem = item" class="py-2 px-4 hover:bg-gray-100 border-b border-solid text-theme-gray-4 text-theme-gray-4 cursor-pointer">
                  {{ item.title }}
               </li>
            </ul>
            <div class="relative">
               <font-awesome-icon size="lg" icon="fa-solid fa-chevron-down" class="text-theme-gray-2 absolute right-0 bottom-1" />
            </div>
         </div>
      </div>
   </div>

</template>

<script setup lang="ts">
import { ref, watch } from 'vue'

const props = defineProps(['data', 'name'])
const emit = defineEmits(['item'])
const isDropOpen = ref<boolean>(false)
const selectedItem = ref<any>(props.data.length ? props.data[0] : '')

watch(selectedItem, (newValue, oldValue) => {
  emit('item', selectedItem, props.name)
})

const toggleDropdown = () => {
   isDropOpen.value = !isDropOpen.value;
}

</script>