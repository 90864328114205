<template>

   <TopBanner layout="full-width" currentPage="Setup Password" heading="Setup Password" bannerClass="banner-auth" />
   <div v-if="status === true" class="flex flex-col gap-12 py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0">
      <div class="flex flex-col w-full lg:w-theme-auth mx-auto gap-5">
         <div class="flex flex-col gap-2">
            <label for="reset-password" class="uppercase text-small text-theme-gray-4">New password *</label>
            <div class="flex flex-row items-center border border-theme-gray-7">
               <input v-model="password" type="password" id="reset-password" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
            </div>
         </div>
         <div class="flex flex-col gap-2">
            <label for="reset-password-repeat" class="uppercase text-small text-theme-gray-4">Repeat password *</label>
            <div class="flex flex-row items-center border border-theme-gray-7">
               <input v-model="passwordConfirmationn" type="password" id="reset-password-repeat" class="w-full outline-none text-theme-gray-5 p-2 h-[40px] md:h-[55px] font-bold text-size-4 md:text-size-6">
            </div>
         </div>
         <div class="flex flex-row items-center gap-4">
            <div @click="handleSubmit" class="gap-4 uppercase rounded-full px-4 w-full md:w-auto md:px-8 py-2 md:py-4 border-2 border-theme-active text-white bg-theme-active hover:text-theme-active hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer ml-auto transition-all ease-in-out duration-500">
               Setup password
            </div>
         </div>
      </div>
   </div>
   <div v-else-if="status === 'not-found'" class="flex flex-col gap-12 py-24 px-11 lg:w-theme-dw md:mx-auto md:px-0">
      <div class="flex flex-col md:flex-row items-center bg-theme-gray-7 px-12 py-10 gap-6">
         <div class="relative bg-white rounded-full min-w-[50px] min-h-[50px] flex flex-row items-center justify-center">
            <span class="font-icon theme-icon warning-icon text-theme-error absolute -top-[23px] -left-[13px] text-[47px]"></span>
         </div>
         <div class="flex flex-col gap-3">
            <h4 class="font-bold text-size-6 text-theme-error">Invalid token</h4>
            <p class="font-light text-size-6 text-theme-error">The token provided is either invalid or expired</p>
         </div>
      </div>
   </div>
   <div v-else class="flex flex-col items-center justify-center gap-4 py-12">
      <img class="w-12" src="/assets/loading.svg" alt="">
      <span>...Loading please wait</span>
   </div>
   <Notification :show="notif.show" :type="notif.type" :message="notif.message" :messageDetails="notif.messageDetails" @closed="notifClosed"/>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import TopBanner from '@/components/TopBanner.vue'
import Auth from '@/composables/Auth'
import { handleApiError } from '@/utils/common'
import { NotificationType } from '@/types'
import Notification from '@/widgets/Notification.vue'
import router from '@/router'

const props = defineProps(['token'])
const password = ref<string>('')
const passwordConfirmationn = ref<string>('')
const isLoading = ref<boolean>(false) 
const status = ref<boolean | string>(false) 
const { setupAgentPassword, checkAgentPasswordSetupToken }  = Auth()
const notif = ref<NotificationType>({ show: false, type: 'success', message: '', messageDetails: ''})

const handleSubmit = async () => {

   if (password.value != '' && passwordConfirmationn.value != '') {

      isLoading.value = true
      const response = await setupAgentPassword(password.value, passwordConfirmationn.value, props.token)
      isLoading.value = false
      
      if (response.status == 200) {
         resetForm()
         router.push({name: 'AdminLogin'})
      } else {
         const error = handleApiError(response)
         notif.value = { show: true, type: 'error', message: error, messageDetails: ''}
         setTimeout(() => { notifClosed() }, 4000)
      }
   }
}

const notifClosed = () => {
   notif.value.show = false
}

const resetForm = () :void => {
   password.value = ''
   passwordConfirmationn.value = ''
}

const mounted = async () => {

   if (props.token == '') {
      status.value = 'not-found'
   } else {

      const response = await checkAgentPasswordSetupToken(props.token)
      if (response.status == 200) {
         status.value = true
      } else {
         status.value = 'not-found'
      }
   }
}

onMounted(() => {
   mounted()
})

</script>