<template>

   <div class="car-info grid grid-cols-5 items-start gap-10 pb-6 pt-10">
      <div class="col-span-5 md:col-span-2">
         <img class="rounded-lg" :src="carr.image && carr.image != '' ? carr.image : '/assets/nocarfound.jpeg'" alt="">
      </div>
      <div class="col-span-5 md:col-span-3 flex flex-col gap-10">
         <div class="flex flex-col md:items-center md:flex-row">
            <div class="md:w-2/3 flex flex-col w-full items-start">
               <div class="text-theme-gray-5 text-size-6 font-bold text-center md:text-left w-full">
                  {{ isLead && isLead == '1' ? carr.type.name : (carr ? carr.name : '') }}
               </div>
               <div v-if="carr && carr.price" class="text-center text-size-7 font-bold text-theme-active w-full">
                  <ul class="flex flex-col w-full items-center justify-center md:items-start md:justify-start">
                     <li style="float:left"><span class="pricinglabel" v-if="userSearch.type == 'hourly'">Price for {{ (userSearch.hours ?? 0) / 10 }} day(s)</span> {{ `$${carr.price.toFixed(2)}` }}</li>
                     <li class="clearfix"></li>
                     <li style="float:left" v-if="userSearch.type == 'hourly'"><span class="pricinglabel">Daily Rate </span><span class="font-size:13px !important; font-weight:bold; color:black">{{ `$${carr.dailyrate.toFixed(2)}` }}</span></li>
                  </ul>
               </div>
               <div v-else class="rounded rounded-bg text-center min-w-[85px] py-2 px-2 my-2 uppercase text-xs bg-theme-button2-bg text-theme-button2-txt">
                  On Request
               </div>
            </div>
            <div v-if="canSelect" class="ml-auto">
               <div v-if="selectedCar && carr && selectedCar.id == carr.id" class="gap-2 uppercase rounded-full px-8 py-3 border-2 border-theme-gray-7 bg-theme-active text-white text-size-2 flex items-center justify-center cursor-pointer w-full md:w-auto md:ml-auto transition-all ease-in-out duration-500">
                  <span>Select</span>
                  <span>
                     <font-awesome-icon size="lg" icon="fa-solid fa-check" class="text-white" />
                  </span>
               </div>
               <div v-else @click="handleSelectCar(carr)" class="uppercase rounded-full px-8 border-2 border-theme-gray-7 bg-theme-gray-7 hover:bg-white text-theme-gray-2 text-size-2 flex items-center justify-center cursor-pointer py-3 mt-4 md:mt-0 md:ml-auto transition-all ease-in-out duration-500">
                  Select
               </div>
            </div>
         </div>
         
         <div class="flex flex-row">
            <div @click="toggleCarMoreInfo" class="flex flex-row items-center gap-3 group cursor-pointer">
               <div class="p-2 flex items-center justify-center rounded-full border border-theme-gray-7 bg-white group-hover:bg-theme-gray-7">
                  <font-awesome-icon size="xs" icon="fa-solid fa-chevron-down" class="text-theme-gray-6" />
               </div>
               <div class="uppercase text-theme-gray-2 text-size-2 tracking-wide group-hover:text-theme-gray-3">More info</div>
            </div>
            <div class="flex flex-col md:flex-row ml-auto gap-2 md:gap-6">
               <div class="flex flex-row items-center gap-5">
                  <div class="">
                     <span class="font-icon theme-icon users-icon text-theme-gray-9 text-[22px] md:text-[47px]"></span>
                  </div>
                  <div class="w-[30px] h-[30px] flex items-center justify-center rounded-full border border-theme-gray-7 bg-white text-theme-gray-6 text-size-2">
                     {{ carr ? carr.type.passengers : '' }}
                  </div>
               </div>
               <div class="flex flex-row items-center gap-5">
                  <div class="">
                     <span class="font-icon theme-icon suitcase-icon text-theme-gray-9 text-[22px] md:text-[47px]"></span>
                  </div>
                  <div class="w-[30px] h-[30px] flex items-center justify-center rounded-full border border-theme-gray-7 bg-white text-theme-gray-6 text-size-2">
                     {{ carr ? carr.type.bags : '' }}
                  </div>
               </div>
            </div>
         </div>
         <div class="flex flex-col gap-10 car-more-info" style="display:none">
            <div v-html="carr && carr.type && carr.type.description ? carr.type.description.replace(/\n/g, '<br>') : ''" class="font-light text-size-4 text-theme-gray-4"></div>
            <div class="grid grid-cols-1 gap-1 bg-theme-gray-1">
               <div class="col-span-1 md:col-span-1 py-6 px-4">
                  <div class="flex flex-col divide-y divide-theme-gray-9 gap-3">
                     <div class="flex flex-row items-center gap-6 py-2">
                        <div class="text-theme-gray-2 text-small uppercase">Cancellation</div>
                        <div class="ml-auto text-theme-gray-5 text-size-3 text-right">Free Cancellation upto 72 hours before Pickup</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
// @ts-ignore
import $ from 'jquery'
import { Car as CarData, UserSearch } from '@/types'
import { retrieveUserSearch } from '@/services/search-service'
import { getHoursPerDay } from '@/utils/common'

const props = defineProps(['canSelect', 'car', 'selectedCar', 'isLead'])
const emit = defineEmits(['selected'])
const userSearch = ref<UserSearch>(retrieveUserSearch())
const days = ref<number>(0)

if (userSearch.value !== undefined && userSearch.value.hours) {
   days.value = userSearch.value.hours / getHoursPerDay()
}

const carr = computed(() => {
   const data = { ...props.car }

   if (userSearch.value) {

      data.dailyrate = 0
      data.price = 0

      if (userSearch.value.type == 'hourly' && props.car.chaufferLocation) {
         data.price = parseFloat(props.car.chaufferLocation.rate_per_hour) * (days.value * props.car.supplier.hours ?? 0)
         data.dailyrate = parseFloat(props.car.chaufferLocation.rate_per_hour) * (props.car.supplier.hours ?? 0)
      } else if (userSearch.value.type == 'distance' && props.car.transferLocation) {
         data.price = parseFloat(props.car.transferLocation.rate)
      }

      if (data.price > 0 && props.car.priceFactor > 0) {
         data.price = data.price / ((100-props.car.priceFactor)/100)
         data.dailyrate = data.dailyrate  / ((100-props.car.priceFactor)/100)
      }
   }

   return data
})

const toggleCarMoreInfo = (event: any) => {
   $(event.currentTarget).closest('.car-info').find('.car-more-info').slideToggle()
}

const handleSelectCar = (car: CarData) => {
   emit('selected', car)
}

</script>